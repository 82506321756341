<template>
    <div class="fixed flex flex-col bottom-0 right-0 z-50 w-full py-2 sm:py-4 sm:px-4 sm:items-end">
        <ProfileContainer v-if="expand" class="w-full sm:shadow-lg max-w-xl">
            <p class="text-sm leading-6 text-gray-900 mb-2">
                <template v-if="submitted">
                    Thanks for your report. We'll look into it.
                </template>
                <template v-else>
                    Having problems with the new site? Let us know.
                </template>
            </p>
            <div class="flex items-start space-x-4">
                <div class="min-w-0 flex-1">
                    <TextInput
                        id="email"
                        type="email"
                        class="mt-1 block w-full text-sm rounded-lg border-0 bg-gray-50 placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
                        v-model="email"
                        required
                        placeholder="Email"
                        autocomplete="username"
                    />

                    <div
                        class="overflow-hidden mt-2 rounded-lg bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                        <label for="report" class="sr-only">Add your comment</label>
                        <textarea rows="4"
                                  name="report"
                                  id="report"
                                  :disabled="submitted"
                                  v-model="report"
                                  autofocus
                                  class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 disabled:text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="Add your comment..."/>
                        <PrimaryButton
                            v-if="submitted"
                            @click="expand = false"
                            class="text-xs m-2">
                            Close
                        </PrimaryButton>
                        <PrimaryButton
                            v-else
                            :disabled="!submitEnabled"
                            @click="submit"
                            class="text-xs m-2 disabled:bg-gray-100 disabled:text-black disabled:cursor-not-allowed">
                            Send
                        </PrimaryButton>
                        <PrimaryLink :link="false" :inverted="true" v-if="legacyLink" :href="legacyLink"
                                     class="text-xs py-2">Back to
                            the old site
                        </PrimaryLink>
                    </div>
                </div>
            </div>
        </ProfileContainer>
        <div
            class="mr-2 mt-2 sm:mr-0 cursor-pointer ml-auto flex-shrink-0 flex flex-row bg-black text-white rounded-full font-bold pr-3 items-center justify-items-center"
            @click="expand = !expand">
            <PhQuestion class="w-10 h-10" weigth="fill"/>
            Help
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {PhQuestion} from "@phosphor-icons/vue";
import ProfileContainer from "@/Components/ProfileContainer.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import PrimaryLink from "@/Components/PrimaryLink.vue";
import TextInput from "@/Components/TextInput.vue";
import Bugsnag from "@bugsnag/js";

const legacyLink = computed(() => usePage().props.legacyLink);
const expand = ref(false);
const report = ref('');
const email = ref(usePage().props.auth.user?.username ?? '');
const submitEnabled = ref(false);
const submitted = ref(false);

watch([report, email], ([newReport, newEmail]) => {
    submitEnabled.value = newReport.length > 0 && newEmail.length > 0;
});

function submit() {
    if (report.value.length > 0 && email.value.length > 0) {
        Bugsnag.setUser(null, email.value, null);
        Bugsnag.notify({
            name: "UserReport",
            message: report.value
        });
        submitted.value = true;
    }
}
</script>
